import React from 'react';
import { useParams } from 'react-router-dom';
import Results from '../../components/results/results';
import Nav from '../../components/nav/Nav';
import styles from './searchResultsPage.module.scss';

const SearchResultsPage: React.FC = () => {
    const { searchTerm } = useParams<{ searchTerm: string }>();

    return (
        <div>
            <Nav currentPage="results" />
            <div className={styles.photoGrid}>
                <Results searchTerm={searchTerm ?? ''} />
            </div>
        </div>
    );
};

export default SearchResultsPage;
