import React, { useEffect, useRef, useState } from 'react';
import Tiles from '../tiles/Tiles';

interface PolygonPoint {
    x: number;
    y: number;
}

interface ImageWithPolygonProps {
    mapData: any;
}

const ImageWithPolygon: React.FC<ImageWithPolygonProps> = ({ mapData }) => {
    const [jsonData, setJsonData] = useState<any | null>(null);
    const [selectedProductName, setSelectedProductName] = useState<
        string | null
    >(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(mapData);
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!jsonData) return;

        const imageUrl = jsonData[0]['data_row.row_data'];
        const objects =
            jsonData[0]['projects.cm10jq7w701xo07uq00w2hdwr.labels'][0]
                .annotations.objects;
        const polygons = objects.map(
            (obj: { polygon: PolygonPoint[] }) => obj.polygon,
        );

        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        const updateCanvasSize = (imageWidth: number, imageHeight: number) => {
            const aspectRatio = imageWidth / imageHeight;
            const canvasWidth = Math.min(window.innerWidth, imageWidth);
            const canvasHeight = canvasWidth / aspectRatio;

            setCanvasSize({ width: canvasWidth, height: canvasHeight });
            return { canvasWidth, canvasHeight };
        };

        if (canvas && context) {
            const image = new Image();
            image.src = imageUrl;

            image.onload = () => {
                const { canvasWidth, canvasHeight } = updateCanvasSize(
                    image.width,
                    image.height,
                );

                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

                polygons.forEach((polygon: PolygonPoint[]) => {
                    context.beginPath();
                    polygon.forEach((point, index) => {
                        const scaledX = (point.x / image.width) * canvasWidth;
                        const scaledY = (point.y / image.height) * canvasHeight;

                        if (index === 0) {
                            context.moveTo(scaledX, scaledY);
                        } else {
                            context.lineTo(scaledX, scaledY);
                        }
                    });
                    context.closePath();
                    context.lineWidth = 2;
                    context.strokeStyle = 'transparent';
                    context.stroke();
                });
            };

            const isPointInPolygon = (
                x: number,
                y: number,
                polygon: PolygonPoint[],
            ) => {
                let inside = false;
                for (
                    let i = 0, j = polygon.length - 1;
                    i < polygon.length;
                    j = i++
                ) {
                    const xi = (polygon[i].x / image.width) * canvas.width,
                        yi = (polygon[i].y / image.height) * canvas.height;
                    const xj = (polygon[j].x / image.width) * canvas.width,
                        yj = (polygon[j].y / image.height) * canvas.height;

                    const intersect =
                        yi > y !== yj > y &&
                        x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                    if (intersect) inside = !inside;
                }
                return inside;
            };

            const handleClick = (event: MouseEvent) => {
                const rect = canvas.getBoundingClientRect();
                const x = event.clientX - rect.left;
                const y = event.clientY - rect.top;

                polygons.forEach((polygon: PolygonPoint[], index: number) => {
                    if (isPointInPolygon(x, y, polygon)) {
                        const polygonName = objects[index].name;
                        setSelectedProductName(polygonName);
                    }
                });
            };

            const handleMouseMove = (event: MouseEvent) => {
                const rect = canvas.getBoundingClientRect();
                const x = event.clientX - rect.left;
                const y = event.clientY - rect.top;
                let isInAnyPolygon = false;

                polygons.forEach((polygon: PolygonPoint[]) => {
                    if (isPointInPolygon(x, y, polygon)) {
                        isInAnyPolygon = true;
                    }
                });

                if (isInAnyPolygon) {
                    canvas.style.cursor = 'pointer';
                } else {
                    canvas.style.cursor = 'default';
                }
            };

            canvas.addEventListener('click', handleClick);
            canvas.addEventListener('mousemove', handleMouseMove);

            return () => {
                canvas.removeEventListener('click', handleClick);
                canvas.removeEventListener('mousemove', handleMouseMove);
            };
        }

        const handleResize = () => {
            const image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                updateCanvasSize(image.width, image.height);
            };
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [jsonData]);

    return (
        <div style={{ width: '100%' }}>
            <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
            {selectedProductName && (
                <Tiles selectedProductName={selectedProductName} />
            )}
        </div>
    );
};

export default ImageWithPolygon;
