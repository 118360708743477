import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useSubmitUmbracoFormEntryMutation } from '../../api/useSubmitUmbracoFormEntry';
import styles from './form-test.module.scss';
import Nav from '../nav/Nav';

const FileUpload: React.FC = () => {
    const [formData, setFormData] = useState({
        genre: '',
        title: '',
        firstName: '',
        lastName: '',
        city: '',
        country: '',
        season: '',
        gender: '',
        description: '',
        fromOrigin: '',
        fot: '',
        email: '',
        phone: '',
        company: '',
        jobTitle: '',
        website: '',
        facebook: '',
        instagram: '',
        twitter: '',
        otherSocial: '',
        photoBy: '',
        photoDate: '',
        item1Brand: '',
        item2Brand: '',
        item3Brand: '',
        item4Brand: '',
        item5Brand: '',
        item6Brand: '',
        item7Brand: '',
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [base64, setBase64] = useState<string | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const { mutateAsync } = useSubmitUmbracoFormEntryMutation();

    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const convertToBase64 = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            setBase64(base64String);
        };
        reader.onerror = error => {
            console.error('Error converting file to base64:', error);
        };
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            convertToBase64(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!selectedFile || !base64) {
            alert('Please select a file first!');
            return;
        }

        const formToSend = {
            contentId: '6595b171-21f8-40ad-9f71-5d8aa9ad0339',
            culture: 'en-US',
            values: {
                ...formData,
                signature: [
                    {
                        fileName: selectedFile.name,
                        fileContents: base64,
                    },
                ],
            },
        };

        try {
            const response = await mutateAsync({
                id: 'aae87082-88f3-4514-b02e-91124262650b',
                formData: formToSend,
            });
            if (response.ok) {
                alert('Form submitted successfully!');
            } else {
                alert('Form submitted successfully!');
            }
        } catch (error) {
            alert('Form submitted successfully!');
        }
    };

    return (
        <div>
            <Nav currentPage="form" />
            <h1 className={styles.h1}>street-photo facts</h1>
            {preview && (
                <div className={styles.preview}>
                    <h3>Image Preview:</h3>
                    <img
                        src={preview}
                        alt="Image preview"
                        style={{ width: '200px', height: 'auto' }}
                    />
                </div>
            )}
            <form className={styles.submitForm} onSubmit={handleSubmit}>
                <input
                    className={styles.customFileInput}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                />
                <input
                    type="text"
                    name="genre"
                    value={formData.genre}
                    onChange={handleInputChange}
                    placeholder="Genre"
                />
                <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Title"
                />
                <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                />
                <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                />
                <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    placeholder="City"
                />
                <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    placeholder="Country"
                />
                <input
                    type="text"
                    name="season"
                    value={formData.season}
                    onChange={handleInputChange}
                    placeholder="Season"
                />
                <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                />
                <input
                    type="text"
                    name="fromOrigin"
                    value={formData.fromOrigin}
                    onChange={handleInputChange}
                    placeholder="From (origin)"
                />
                <select
                    name="fot"
                    value={formData.fot}
                    onChange={handleInputChange}
                >
                    <option value="">Select F.O.T</option>
                    <option value="Definitely">Definitely</option>
                    <option value="Yes">Yes</option>
                    <option value="Maybe">Maybe</option>
                    <option value="No">No</option>
                </select>
                <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                />
                <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Contact"
                />
                <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    placeholder="Company"
                />
                <input
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    placeholder="Job Title"
                />
                <input
                    type="text"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                    placeholder="Website"
                />
                <input
                    type="text"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleInputChange}
                    placeholder="Facebook"
                />
                <input
                    type="text"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleInputChange}
                    placeholder="Instagram"
                />
                <input
                    type="text"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleInputChange}
                    placeholder="Twitter"
                />
                <input
                    type="text"
                    name="otherSocial"
                    value={formData.otherSocial}
                    onChange={handleInputChange}
                    placeholder="Other Social"
                />
                <input
                    type="text"
                    name="photoBy"
                    value={formData.photoBy}
                    onChange={handleInputChange}
                    placeholder="Photographer"
                />
                <input
                    type="date"
                    name="photoDate"
                    value={formData.photoDate}
                    onChange={handleInputChange}
                    placeholder="Date"
                />
                <input
                    type="text"
                    name="item1Brand"
                    value={formData.item1Brand}
                    onChange={handleInputChange}
                    placeholder="Item 1 + Brand"
                />
                <input
                    type="text"
                    name="item2Brand"
                    value={formData.item2Brand}
                    onChange={handleInputChange}
                    placeholder="Item 2 + Brand"
                />
                <input
                    type="text"
                    name="item3Brand"
                    value={formData.item3Brand}
                    onChange={handleInputChange}
                    placeholder="Item 3 + Brand"
                />
                <input
                    type="text"
                    name="item4Brand"
                    value={formData.item4Brand}
                    onChange={handleInputChange}
                    placeholder="Item 4 + Brand"
                />
                <input
                    type="text"
                    name="item5Brand"
                    value={formData.item5Brand}
                    onChange={handleInputChange}
                    placeholder="Item 5 + Brand"
                />
                <input
                    type="text"
                    name="item6Brand"
                    value={formData.item6Brand}
                    onChange={handleInputChange}
                    placeholder="Item 6 + Brand"
                />
                <input
                    type="text"
                    name="item7Brand"
                    value={formData.item7Brand}
                    onChange={handleInputChange}
                    placeholder="Item 7 + Brand"
                />
                <button className={styles.fileButton} type="submit">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default FileUpload;
