import React from 'react';
import styles from './clique-home.module.scss';
import PhotoGrid from '../../components/photoGrid/PhotoGrid';
import AuthorInfo from '../../components/author/Author';
import HeroSection from '../../components/heroSection/HeroSection';
import Popup from '../../components/popup/Popup';
import CliqueNav from '../../components/cliqueNav/CliqueNav';
import CliquePhotoGrid from '../../components/cliquePhotoGrid/CliquePhotoGrid';

function CliqueHome() {
    return (
        <>
            <CliqueNav currentPage="clique" />
            <HeroSection />
            <div className={styles.container}>
                <CliquePhotoGrid />
            </div>
            <Popup />
        </>
    );
}

export default CliqueHome;
