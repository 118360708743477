import React from 'react';
import styles from './privacyPolicy.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';

function PrivacyPolicy() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '3d8d5602-0339-4f2a-bccb-70dd1b879d51',
    );

    const {
        termsAndConditionsLink,
        privacyPolicyLink,
        cookiePolicyLink,
        termsAndConditionsTitle,
        termsAndConditionsText,
        privacyPolicyTitle,
        privacyPolicyText,
        cookiePolicyTitle,
        cookiePolicyText,
    } = data?.properties || {};

    return (
        <>
            <p className={styles.heading}>LEGAL</p>
            <p className={styles.secondaryHeading}>
                <a href="#terms">{termsAndConditionsLink}</a>
            </p>
            <p className={styles.secondaryHeading}>
                <a href="#privacy">{privacyPolicyLink}</a>
            </p>
            <p className={styles.secondaryHeading}>
                <a href="#cookie">{cookiePolicyLink}</a>
            </p>
            <p id="terms" className={styles.heading}>
                {termsAndConditionsTitle}
            </p>
            <div
                className={styles.paragraph}
                dangerouslySetInnerHTML={{
                    __html: termsAndConditionsText?.markup,
                }}
            />
            <p id="privacy" className={styles.heading}>
                {privacyPolicyTitle}
            </p>
            <div
                className={styles.paragraph}
                dangerouslySetInnerHTML={{ __html: privacyPolicyText?.markup }}
            />
            <p id="cookie" className={styles.heading}>
                {cookiePolicyTitle}
            </p>
            <div
                className={styles.paragraph}
                dangerouslySetInnerHTML={{ __html: cookiePolicyText?.markup }}
            />
        </>
    );
}

export default PrivacyPolicy;
