import React from 'react';
import styles from './about-us.module.scss';
import Nav from '../../components/nav/Nav';
import InfoCard from '../../components/infoCard/InfoCard';
import About from '../../components/about/About';
function AboutUs() {
    return (
        <div className="AboutUs">
            <Nav currentPage="about-us" />
            <div className={styles.heroImage}></div>
            <div className={styles.container}>
                <About />
                <div className={styles.info}>
                    <InfoCard title={'CITIES'} />
                    <InfoCard title={'PHOTOGRAPHERS'} />
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
