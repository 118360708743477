import React from 'react';
import AppRoutes from './routes';
import Footer from './components/footer/Footer';
import CliqueFooter from './components/cliqueFooter/CliqueFooter';

const App: React.FC = () => {
    const pathname = window.location.pathname;

    return (
        <div className="App" style={{ overflowX: 'hidden' }}>
            <AppRoutes />
            {pathname.includes('/shop-pages-clique') ? (
                <CliqueFooter />
            ) : (
                <Footer />
            )}
        </div>
    );
};

export default App;
