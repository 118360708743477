import React, { useRef, useEffect, useState, useMemo } from 'react';
import 'video.js/dist/video-js.css';
import TilesVideo from '../tilesVideo/TilesVideo';
import styles from './videoWithAnnotations.module.scss';
import { useMediaQuery } from 'react-responsive';
import Close from '../../assets/img/close.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import CliqueTilesVideo from '../cliqueTilesVideo/CliqueTilesVideo';
import VideoJS from '../videoJS/videoJS';

interface BoundingBox {
    top: number;
    left: number;
    width: number;
    height: number;
    name: string;
}

interface Annotations {
    [frame: number]: BoundingBox[];
}

interface VideoWithAnnotationsProps {
    mapVideoData: any;
    isHorizontal: any;
}

const VideoWithAnnotations: React.FC<VideoWithAnnotationsProps> = ({
    mapVideoData,
    isHorizontal,
}) => {
    const playerRef = useRef<any>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [jsonData, setJsonData] = useState<any | null>(null);
    const frameRef = useRef(0);
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSliderVisible, setIsSliderVisible] = useState(false);
    const [videoDimensions, setVideoDimensions] = useState({
        width: 0,
        height: 0,
    });
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });
    const pathname = window.location.pathname;
    const sliderRef = useRef<Slider>(null);

    useEffect(() => {
      const minProductsToScroll = isMobile ? 3 : 4;
      if (sliderRef.current && selectedProducts.length >= minProductsToScroll) {
          sliderRef.current.slickGoTo(selectedProducts.length - (isMobile ? 3 : 4));
      }
    }, [selectedProducts.length, isMobile]);  
  

    const handlePlayPause = () => {
        setIsPlaying(prevIsPlaying => !prevIsPlaying);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(mapVideoData);
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
            }
        };

        fetchData();
    }, [mapVideoData]);

    useEffect(() => {
        if (jsonData) {
            drawAnnotations(frameRef.current, mapAnnotations(jsonData));
        }
    }, [frameRef.current, jsonData, canvasSize]);

    const drawAnnotations = (frame: number, annotations: Annotations) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const frameAnnotations = annotations[frame];
        if (frameAnnotations) {
            frameAnnotations.forEach(({ top, left, width, height }) => {
                ctx.strokeStyle = 'transparent';
                ctx.lineWidth = 2;
                ctx.strokeRect(left, top, width, height);
            });
        }
    };

    const scaleCoordinates = (
        coord: number,
        originalSize: number,
        canvasSize: number,
    ) => {
        return (coord / originalSize) * canvasSize;
    };

    const handleResize = () => {
        const canvas = canvasRef.current;
        if (canvas && playerRef.current) {
            const videoElement = playerRef.current
                .el()
                .querySelector('video') as HTMLVideoElement;
            if (videoElement) {
                setCanvasSize({
                    width: videoElement.videoWidth,
                    height: videoElement.videoHeight,
                });
            }
        }
    };

    useEffect(() => {
        const videoElement =
            playerRef.current?.getInternalPlayer() as HTMLVideoElement;
        if (videoElement) {
            videoElement.setAttribute('playsinline', 'true');
        }
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const annotations = mapAnnotations(jsonData);
        const frameAnnotations = annotations[frameRef.current];

        if (frameAnnotations) {
            frameAnnotations.forEach(({ top, left, width, height, name }) => {
                const scaledLeft = scaleCoordinates(
                    left,
                    mediaWidth,
                    canvas.clientWidth,
                );
                const scaledTop =
                    scaleCoordinates(top, mediaHeight, canvas.clientHeight) +
                    (isDesktop ? 50 : 0);
                const scaledWidth = scaleCoordinates(
                    width,
                    mediaWidth,
                    canvas.clientWidth,
                );
                const scaledHeight = scaleCoordinates(
                    height,
                    mediaHeight,
                    canvas.clientHeight,
                );

                if (
                    x >= scaledLeft &&
                    x <= scaledLeft + scaledWidth &&
                    y >= scaledTop &&
                    y <= scaledTop + scaledHeight
                ) {
                    setSelectedProducts(prevSelected => {
                        if (prevSelected.includes(name)) {
                            return [...prevSelected];
                        } else {
                            return [...prevSelected, name];
                        }
                    });
                }
            });
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const annotations = mapAnnotations(jsonData);
        const frameAnnotations = annotations[frameRef.current];
        let isInBoundingBox = false;

        if (frameAnnotations) {
            frameAnnotations.forEach(({ top, left, width, height }) => {
                const scaledLeft = scaleCoordinates(
                    left,
                    mediaWidth,
                    canvas.clientWidth,
                );
                const scaledTop =
                    scaleCoordinates(top, mediaHeight, canvas.clientHeight) +
                    (isDesktop ? 50 : 0);
                const scaledWidth = scaleCoordinates(
                    width,
                    mediaWidth,
                    canvas.clientWidth,
                );
                const scaledHeight = scaleCoordinates(
                    height,
                    mediaHeight,
                    canvas.clientHeight,
                );

                if (
                    x >= scaledLeft &&
                    x <= scaledLeft + scaledWidth &&
                    y >= scaledTop &&
                    y <= scaledTop + scaledHeight
                ) {
                    isInBoundingBox = true;
                }
            });
        }

        canvas.style.cursor = isInBoundingBox ? 'pointer' : 'default';
    };

    const handleProgress = (state: { playedSeconds: number }) => {
        const frame_rate = jsonData
            ? jsonData[0]['media_attributes.frame_rate']
            : '';
        const frame_count = jsonData
            ? jsonData[0]['media_attributes.frame_count']
            : '';
        const currentFrame = Math.floor(state.playedSeconds * frame_rate);
        if (currentFrame < frame_count) {
            frameRef.current = currentFrame;
        }
    };

    const mapAnnotations = (data: any): Annotations => {
        const annotations: Annotations = {};

        data.forEach(
            (item: {
                [x: string]: {
                    annotations: {
                        frames: { [key: string]: { objects: any } };
                    };
                }[];
            }) => {
                const frames = item[
                    'projects.cm1ixgtzz04pa07xqczgrhcim.labels'
                ][0].annotations.frames as { [key: string]: { objects: any } };
                for (const frameNumber in frames) {
                    const objects = frames[frameNumber].objects;
                    annotations[parseInt(frameNumber)] = Object.keys(
                        objects,
                    ).map(key => {
                        const boundingBox = objects[key].bounding_box;
                        const name = objects[key].name;

                        return {
                            ...boundingBox,
                            name: name,
                        };
                    });
                }
            },
        );

        return annotations;
    };

    const videoUrl = jsonData ? jsonData[0]['data_row.row_data'] : '';
    const mediaHeight = jsonData ? jsonData[0]['media_attributes.height'] : '';
    const mediaWidth = jsonData ? jsonData[0]['media_attributes.width'] : '';

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 3 : 4,
        slidesToScroll: 1,
    };

    const toggleSliderVisibility = () => {
        setIsSliderVisible(prev => !prev);
    };

    const handlePlayerReady = (player: any) => {
        console.log('Player is ready!', player);
    };

    useEffect(() => {
        const video = document.createElement('video');
        video.src = videoUrl;

        video.addEventListener('loadedmetadata', () => {
            let { videoWidth, videoHeight } = video;

            const maxWidth = window.innerWidth;

            if (isMobile) {
                if (videoWidth > maxWidth) {
                    const scaleFactor = maxWidth / videoWidth;
                    videoWidth = maxWidth;
                    videoHeight = Math.round(videoHeight * scaleFactor);
                }
            } else if (isHorizontal > 480) {
                if (videoWidth > 1000) {
                    const scaleFactor = 1000 / videoWidth;
                    videoWidth = 1000;
                    videoHeight = Math.round(videoHeight * scaleFactor);
                }
            } else {
                if (videoWidth > 480) {
                    const scaleFactor = 480 / videoWidth;
                    videoWidth = 480;
                    videoHeight = Math.round(videoHeight * scaleFactor);
                }
            }

            setVideoDimensions({
                width: videoWidth,
                height: videoHeight,
            });
        });

        return () => {
            video.removeAttribute('src');
            video.load();
        };
    }, [videoUrl]);

    const memoizedVideoJS = useMemo(
        () => (
            <VideoJS
                url={videoUrl}
                playing={isPlaying}
                controls={true}
                autoPlay={true}
                playsinline={true}
                width={`${videoDimensions.width}px`}
                height={`${videoDimensions.height}px`}
                onReady={handlePlayerReady}
                onProgress={handleProgress}
            />
        ),
        [videoUrl, isPlaying, videoDimensions],
    );

    return isMobile ? (
        <div className={styles.isMobileContainer}>
            <div className={styles.isMobilePlayerWrapper}>
                {videoDimensions.width &&
                    videoDimensions.height &&
                    memoizedVideoJS}
                <canvas
                    ref={canvasRef}
                    className={styles.isMobileCanvas}
                    width={canvasSize.width}
                    height={canvasSize.height}
                    onClick={handleCanvasClick}
                    onMouseMove={handleMouseMove}
                />
                <div
                    className={`${styles.defaultStyleTiles} ${
                        selectedProducts.length > 1
                            ? styles.multipleTilesVideo
                            : styles.tilesVideo
                    }`}
                >
                    {isSliderVisible && (
                        <div
                            className={styles.sliderContainer}
                            style={isHorizontal > 30 ? { bottom: '0px' } : {}}
                        >
                            <Slider ref={sliderRef} {...sliderSettings}>
                                {selectedProducts.map((productName, index) =>
                                    pathname.includes('/shop-pages-clique') ? (
                                        <CliqueTilesVideo
                                            key={index}
                                            selectedProductName={productName}
                                            setWidth={isHorizontal}
                                        />
                                    ) : (
                                        <TilesVideo
                                            key={index}
                                            selectedProductName={productName}
                                            setWidth={isHorizontal}
                                        />
                                    ),
                                )}
                            </Slider>
                        </div>
                    )}
                    {selectedProducts.length > 0 ? (
                        <div
                            onClick={toggleSliderVisibility}
                            className={`${styles.storeButton} ${!isSliderVisible ? styles.blob : ''}`}
                            style={isHorizontal > 30 ? { bottom: '61px' } : {}}
                        >
                            {isSliderVisible ? (
                                <img width="10px" height="10px" src={Close} />
                            ) : (
                                selectedProducts.length
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* <div className={styles.customControls}>
                <button onClick={handlePlayPause}>
                    {isPlaying ? (
                        <img className={styles.pausePlay} src={Pause} />
                    ) : (
                        <img className={styles.pausePlay} src={Play} />
                    )}
                </button>
            </div> */}
        </div>
    ) : (
        <div className={styles.isDesktopContainer}>
            <div className={styles.isDesktopWrapper}>
                <div className={styles.isDesktopWrapper2}>
                    <div className={styles.isDesktopPlayerWrapper}>
                        {videoDimensions.width &&
                            videoDimensions.height &&
                            memoizedVideoJS}
                        <canvas
                            ref={canvasRef}
                            className={styles.isDesktopCanvas}
                            width={canvasSize.width}
                            height={canvasSize.height}
                            onClick={handleCanvasClick}
                            onMouseMove={handleMouseMove}
                        />
                    </div>
                </div>
            </div>
            <div
                className={
                    selectedProducts.length > 1
                        ? styles.multipleTilesVideo
                        : styles.tilesVideo
                }
                style={{ display: 'flex', gap: '10px' }}
            >
                {isSliderVisible && (
                    <div
                        className={styles.sliderContainer}
                        style={isHorizontal > 30 ? { maxWidth: '921px' } : {}}
                    >
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {selectedProducts.map((productName, index) =>
                                pathname.includes('/shop-pages-clique') ? (
                                    <CliqueTilesVideo
                                        key={index}
                                        selectedProductName={productName}
                                        setWidth={isHorizontal}
                                    />
                                ) : (
                                    <TilesVideo
                                        key={index}
                                        selectedProductName={productName}
                                        setWidth={isHorizontal}
                                    />
                                ),
                            )}
                        </Slider>
                    </div>
                )}
                {selectedProducts.length > 0 ? (
                    <div
                        onClick={toggleSliderVisibility}
                        className={`${styles.storeButton} ${styles.blob}`}
                    >
                        {isSliderVisible ? (
                            <img width="12px" height="12px" src={Close} />
                        ) : (
                            selectedProducts.length
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default VideoWithAnnotations;
