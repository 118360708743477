import React from 'react';
import styles from './hero-section.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import AuthorInfo from '../author/Author';

function HeroSection() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '6595b171-21f8-40ad-9f71-5d8aa9ad0339',
    );
    const { heading, paragraph, paragraph2 } = data?.properties || {};

    return (
        <div className={styles.heroImage}>
            <div className={styles.heroSectionWrapper}>
                <AuthorInfo />
                <div className={styles.heroText}>
                    <div
                        dangerouslySetInnerHTML={{ __html: heading?.markup }}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: paragraph?.markup }}
                    />
                    <div className={styles.button}>
                        <button>
                            <u>
                                <a href="/how-it-works">How it works</a>
                            </u>
                        </button>
                    </div>
                    <hr className={styles.hrHero}></hr>
                    <div
                        dangerouslySetInnerHTML={{ __html: paragraph2?.markup }}
                    />
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
