import React, { useEffect, useState } from 'react';
import styles from './tilesVideo.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import { useGetUmbracoContentByTypeQuery } from '../../api/useGetUmbracoContentByTypeQuery';
import { useLocation } from 'react-router-dom';

type UmbraccoImage = {
    url: string;
};

type UmbraccoPhotoItem = {
    content: {
        properties: {
            minatureImage: UmbraccoImage[];
            affiliateLink: string;
            productName: string;
            displayedName: string;
            brand: string;
        };
    };
};

interface TilesProps {
    selectedProductName: string | null;
    setWidth: any;
}

function TilesVideo({ selectedProductName, setWidth }: TilesProps) {
    const [data, setData] = useState<any>();
    const contentTypeAlias = 'addShopPage';
    const {
        data: umbracoData,
        error: umbracoError,
        isLoading: umbracoIsLoading,
    } = useGetUmbracoContentByTypeQuery(contentTypeAlias);
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname.replace(/\/$/, '');

        const findObjectByUrl = (url: string) => {
            return umbracoData?.find(item => {
                const itemPath = item.route.path.replace(/\/$/, '');
                return itemPath === url;
            });
        };

        const result = findObjectByUrl(currentPath);

        if (result) setData(result);
    }, [umbracoData, location.pathname]);

    if (umbracoError) {
        return <div>Error loading content</div>;
    }

    if (umbracoIsLoading) {
        return <div></div>;
    }

    const { properties } = data || {};
    const { addItem } = properties || {};
    const items: UmbraccoPhotoItem[] = addItem?.items || [];
    return (
        <div className={styles.tiles}>
            {items
                .filter(item => {
                    const productName = item.content.properties.productName
                        .trim()
                        .toLowerCase();
                    const selectedName = selectedProductName
                        ?.trim()
                        .toLowerCase();
                    return productName === selectedName;
                })
                .map((item: UmbraccoPhotoItem, index: number) => {
                    const {
                        content: {
                            properties: {
                                minatureImage,
                                affiliateLink,
                                productName,
                                brand,
                                displayedName,
                            },
                        },
                    } = item;
                    const imageUrl = minatureImage[0]?.url
                        ? `https://admintraffique.astroid.com.pl${minatureImage[0].url}`
                        : '';

                    return (
                        <div
                            className={styles.tile}
                            style={
                                setWidth > 30 && window.innerWidth <= 1026
                                    ? { maxWidth: '50px', maxHeight: '50px' }
                                    : {}
                            }
                            key={index}
                        >
                            <a
                                className={styles.href}
                                href={affiliateLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className={styles.minatureContainer}>
                                    <img
                                        className={styles.minature}
                                        src={imageUrl}
                                        alt={displayedName}
                                    />
                                </div>
                            </a>
                        </div>
                    );
                })}
        </div>
    );
}

export default TilesVideo;
