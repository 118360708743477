import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import HowItWorks from './pages/howItWorks/HowItWorks';
import QuickChat from './pages/quickChat/QuickChat';
import Shop from './pages/shop/Shop';
import Photographer from './pages/photographer/Photographer';
import FormTest from './components/form/FormTest';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Photographers from './pages/photographers/photographers';
import { useGetUmbracoContentByTypeQuery } from './api/useGetUmbracoContentByTypeQuery';
import { useGetUmbracoPhotographers } from './api/useGetUmbracoPhotographers';
import SearchResultsPage from './pages/searchResultsPage/searchResultsPage';
import Loader from './components/loader/Loader';
import QuickChats from './pages/quickChats/QuickChats';
import CliqueHome from './pages/cliqueHome/CliqueHome';
import CliqueShop from './pages/clique-shop/CliqueShop';

const AppRoutes: React.FC = () => {
    const contentTypeAlias = 'addShopPage';
    const contentTypeAlias2 = 'photographerInfo';
    const contentTypeAlias3 = 'quick';
    const { data, error, isLoading } =
        useGetUmbracoContentByTypeQuery(contentTypeAlias);
    const { data: dataClique } =
        useGetUmbracoContentByTypeQuery('addShopPageClique');
    const { data: photographersData } =
        useGetUmbracoPhotographers(contentTypeAlias2);
    const { data: quickData } = useGetUmbracoPhotographers(contentTypeAlias3);
    if (isLoading) return <Loader />;
    if (error) return <div>Error loading data</div>;

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop-pages-clique" element={<CliqueHome />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/form" element={data && <FormTest />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                    path="/search/:searchTerm"
                    element={<SearchResultsPage />}
                />
                <Route path="/quick-chats" element={<QuickChats />} />
                {quickData &&
                    quickData.map((quick: any) => (
                        <Route
                            key={quick.id}
                            path={`/quick-chat/${quick.properties.quickChatName.toLowerCase().replace(/\s+/g, '-')}`}
                            element={<QuickChat quickChat={quick} />}
                        />
                    ))}
                <Route path="/photographers" element={<Photographers />} />
                {photographersData &&
                    photographersData.map((photographer: any) => (
                        <Route
                            key={photographer.id}
                            path={`/photographer/${photographer.properties.photographerName.toLowerCase().replace(/\s+/g, '-')}`}
                            element={
                                <Photographer photographer={photographer} />
                            }
                        />
                    ))}
                {data
                    ?.filter((item: any) => item.name !== 'Shop Pages')
                    .map((item: any) => (
                        <Route
                            key={item.id}
                            path={item.route.path}
                            element={<Shop data={item} />}
                        />
                    ))}
                {dataClique
                    ?.filter((item: any) => item.name !== 'Shop Pages Clique')
                    .map((item: any) => (
                        <Route
                            key={item.id}
                            path={item.route.path}
                            element={<CliqueShop data={item} />}
                        />
                    ))}
            </Routes>
        </Router>
    );
};

export default AppRoutes;
