import React from 'react';
import PhotoItem from '../photoItem/PhotoItem';
import styles from './photo-grid.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import Loader from '../loader/Loader';

type UmbraccoPhoto = {
    url: string;
};

type UmbraccoPhotoItem = {
    content: {
        properties: {
            photo: UmbraccoPhoto[];
            pageName: string | null;
            photoAuthor: string | null;
        };
    };
};

function PhotoGridPhotographer() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '6595b171-21f8-40ad-9f71-5d8aa9ad0339',
    );

    if (error) {
        return <div>Error loading content</div>;
    }

    if (isLoading) {
        return <Loader />;
    }

    const currentUrl = window.location.href;
    const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

    const { properties } = data || {};
    const { photos } = properties || {};
    const items: UmbraccoPhotoItem[] = photos?.items || [];

    const filteredItems = items.filter(item => {
        const { photoAuthor } = item.content.properties;
        if (photoAuthor) {
            const formattedAuthor = photoAuthor
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/-+/g, '-');
            return formattedAuthor === lastSegment;
        }
        return false;
    });

    const photoUrls = filteredItems.map((photo: UmbraccoPhotoItem) => {
        const {
            content: {
                properties: { photo: photoArray, pageName },
            },
        } = photo;
        const url = photoArray[0].url;
        const formattedPageName = pageName
            ? pageName.toLowerCase().replace(/\s+/g, '-').replace(/-+/g, '-')
            : '';
        return { url, pageName: formattedPageName };
    });

    return (
        <div className={styles.masonryGrid}>
            {photoUrls.map(({ url, pageName }, index: number) => (
                <PhotoItem key={index} url={url} pageName={pageName} />
            ))}
        </div>
    );
}

export default PhotoGridPhotographer;
