import React from 'react';
import Nav from '../../components/nav/Nav';
import styles from './quick-chats.module.scss';
import { useGetUmbracoPhotographers } from '../../api/useGetUmbracoPhotographers';

const QuickChats: React.FC = () => {
    const contentTypeAlias2 = 'quick';
    const {
        data: quicks,
        error,
        isLoading,
    } = useGetUmbracoPhotographers(contentTypeAlias2);

    return (
        <div className="Photographer">
            <Nav currentPage="quick-chats" />
            <div className={styles.heroImage} />

            {isLoading && <p>Loading...</p>}
            {error && <p>Error loading photographers.</p>}
            <div className={styles.photographersContainer}>
                {quicks &&
                    quicks.map((quick: any) => (
                        <a
                            key={quick.id}
                            href={`/quick-chat/${quick.properties.quickChatName.toLowerCase().replace(/\s+/g, '-')}`}
                        >
                            <div className={styles.container}>
                                <div className={styles.text}>
                                    <p className={styles.paragraph2}>
                                        Quick Chat
                                    </p>
                                    <h1 className={styles.heading}>
                                        {quick.properties.quickChatName}
                                    </h1>
                                </div>
                            </div>
                        </a>
                    ))}
            </div>
        </div>
    );
};

export default QuickChats;
