import axios from 'axios';
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
} from 'react-query';

interface FormData {
    values: {
        title: string;
        firstName: string;
        lastName: string;
        city: string;
        country: string;
        season: string;
        gender: string;
        description: string;
        fot: string;
        email: string;
        phone: string;
        company: string;
        jobTitle: string;
        website: string;
        facebook: string;
        instagram: string;
        twitter: string;
        photoBy: string;
        photoDate: string;
        signature: Array<{
            fileName: string;
            fileContents: string;
        }>;
    };
    contentId: string;
    culture: string;
}

const submitUmbracoFormEntry = async ({
    id,
    formData,
}: {
    id: string;
    formData: FormData;
}): Promise<any> => {
    try {
        const apiKey = 'Q0dvfM6y9uR3Gf8P1zF2I6Khj7L8n9MbQ3P6s0T3Z4W7t2X3R4';

        const dataToSend = {
            ...formData,
            contentId: formData.contentId,
            culture: formData.culture,
        };
        const response = await axios.post<any>(
            `https://admintraffique.astroid.com.pl/umbraco/forms/delivery/api/v1/entries/${id}`,
            dataToSend,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Api-Key': apiKey,
                },
            },
        );

        return response.data;
    } catch (error: any) {
        if (error.response) {
            throw new Error(
                `Error submitting form entry with id ${id}: ${error.response.status}`,
            );
        } else {
            throw new Error(
                `Error submitting form entry with id ${id}: ${error.message}`,
            );
        }
    }
};

export const useSubmitUmbracoFormEntryMutation = (
    options?: UseMutationOptions<
        any,
        Error,
        { id: string; formData: FormData }
    >,
): UseMutationResult<any, Error, { id: string; formData: FormData }> => {
    return useMutation<any, Error, { id: string; formData: FormData }>(
        ({ id, formData }) => submitUmbracoFormEntry({ id, formData }),
        options,
    );
};
