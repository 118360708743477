import styles from './loader.module.scss';
import { ClipLoader } from 'react-spinners';

function Loader() {
    return (
        <div className={styles.loaderContainer}>
            <ClipLoader
                color="#000000"
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default Loader;
