import React, { useEffect, useState } from 'react';
import styles from './info-card.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import { Link } from 'react-router-dom';

interface InfoCardProps {
    title: string;
}

interface City {
    content: {
        properties: {
            cityname: string;
        };
    };
}

interface Photographer {
    content: {
        properties: {
            photographerName: string;
        };
    };
}

function InfoCard({ title }: InfoCardProps) {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '37ecb2ac-328c-468e-8444-a4fcb5a53fe2',
    );
    const [infoNames, setInfoNames] = useState<string[]>([]);
    const [infoNames2, setInfoNames2] = useState<string[]>([]);

    useEffect(() => {
        if (data) {
            if (title.toUpperCase() === 'CITIES') {
                const cities = data?.properties.cities.items as City[];
                const cityNames = cities?.map(
                    ({
                        content: {
                            properties: { cityname },
                        },
                    }) => cityname,
                );
                setInfoNames2(cityNames);
            } else if (title.toUpperCase() === 'PHOTOGRAPHERS') {
                const photographers = data?.properties.photographers
                    .items as Photographer[];
                const photographerNames = photographers?.map(
                    ({
                        content: {
                            properties: { photographerName },
                        },
                    }) => photographerName,
                );
                setInfoNames(photographerNames);
            }
        }
    }, [data, title]);

    return (
        <div>
            <h2 className={styles.title}>{title}</h2>
            {infoNames2?.map(
                (name: string, index: React.Key | null | undefined) => (
                    <Link
                        className={styles.name}
                        key={index}
                        to={`/search/${name.replace(/\s+/g, '-').toLowerCase()}`}
                    >
                        {name}
                    </Link>
                ),
            )}
            {infoNames?.map(
                (name: string, index: React.Key | null | undefined) => (
                    <a
                        href={`/photographer/${name.replace(/\s+/g, '-').toLowerCase()}`}
                    >
                        <h2 className={styles.name} key={index}>
                            {name}
                        </h2>
                    </a>
                ),
            )}
        </div>
    );
}

export default InfoCard;
