import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

export const useGetUmbraccoContentQuery = (
    contentId: string,
    options?: UseQueryOptions<any, any>,
) => {
    const getUmbraccoContent = async (): Promise<any> => {
        try {
            const response = await axios.get<any>(
                `https://admintraffique.astroid.com.pl/umbraco/delivery/api/v2/content/item/${contentId}`,
            );
            return response.data;
        } catch (error: any) {
            const status = error.response?.status || 500;
            throw new Error(
                `Error fetching content with id ${contentId}: ${status}`,
            );
        }
    };

    return useQuery<any, any>(
        ['getUmbraccoContent', contentId],
        getUmbraccoContent,
        {
            ...options,
            enabled: !!contentId,
        },
    );
};
