import React from 'react';
import styles from './how-it-works.module.scss';
import Nav from '../../components/nav/Nav';
import How from '../../components/how/How';

function HowItWorks() {
    return (
        <div className="HowItWorks">
            <Nav currentPage="how-it-works" />
            <div className={styles.heroImage}></div>
            <div className={styles.container}>
                <How />
            </div>
        </div>
    );
}

export default HowItWorks;
