import React from 'react';
import Nav from '../../components/nav/Nav';
import styles from './quick-chat.module.scss';
import Quick from '../../components/quick/Quick';
import Loader from '../../components/loader/Loader';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import UpImage from '../../assets/img/up_arrow.png';

type UmbraccoImage = {
    url: string;
};

const QuickChat: React.FC<{ quickChat: any }> = ({ quickChat }) => {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '35ffe33e-b320-4080-9ff6-d33e69a9d635',
    );
    const url = 'https://admintraffique.astroid.com.pl/';

    const renderImage = (image: string | UmbraccoImage[]) => {
        if (Array.isArray(image) && image.length > 0) {
            return <img src={url + image[0].url} alt="" />;
        }
        return null;
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (isLoading) return <Loader />;
    if (error) return <p>Error loading data</p>;

    const {
        heading,
        paragraph,
        paragraph2,
        paragraph3,
        paragraph4,
        paragraph5,
        paragraph6,
        paragraph7,
        image,
        image2,
        image3,
        image4,
        image5,
        image6,
    } = quickChat?.properties || {};

    return (
        <div className="QuickChat">
            <Nav currentPage="quick-chat" />
            <div className={styles.heroImage} />
            <div className={styles.container}>
                <div
                    className={styles.heading}
                    dangerouslySetInnerHTML={{ __html: heading?.markup || '' }}
                />
                <div className={styles.columnContainer}>
                    <div className={styles.column}>
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph?.markup || '',
                            }}
                        />
                        {renderImage(image)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph2?.markup || '',
                            }}
                        />
                        {renderImage(image2)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph3?.markup || '',
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        {renderImage(image3)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph4?.markup || '',
                            }}
                        />
                        {renderImage(image4)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph5?.markup || '',
                            }}
                        />
                        {renderImage(image5)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph6?.markup || '',
                            }}
                        />
                        {renderImage(image6)}
                        <div
                            className={styles.paragraph}
                            dangerouslySetInnerHTML={{
                                __html: paragraph7?.markup || '',
                            }}
                        />
                        <img
                            className={styles.arrow}
                            src={UpImage}
                            onClick={scrollToTop}
                        />
                        <hr className={styles.hr} />
                        <a href="/quick-chats">
                            <h3 className={styles.other}>OTHER CHATS</h3>
                            <h2 className={styles.author}>
                                Leanne Hirsh Talks Style
                            </h2>
                            <h2 className={styles.author}>
                                Donaldo Barros Talks Photography
                            </h2>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickChat;
