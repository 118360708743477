import React from 'react';
import styles from './privacypolicy.module.scss';
import Nav from '../../components/nav/Nav';
import PrivacyPolicyContent from '../../components/privacyPolicy/PrivacyPolicyContent';
function PrivacyPolicy() {
    return (
        <div className="AboutUs">
            <Nav currentPage="about-us" />
            <div className={styles.heroImage}></div>
            <div className={styles.container}>
                <PrivacyPolicyContent />
            </div>
        </div>
    );
}

export default PrivacyPolicy;
