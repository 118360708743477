import React from 'react';
import styles from './about.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';

function About() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '37ecb2ac-328c-468e-8444-a4fcb5a53fe2',
    );
    const { heading, secondaryHeading, paragraph } = data?.properties || {};

    return (
        <>
            <div
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: heading?.markup }}
            />
            <div
                className={styles.secondaryHeading}
                dangerouslySetInnerHTML={{ __html: secondaryHeading?.markup }}
            />
            <div
                className={styles.paragraph}
                dangerouslySetInnerHTML={{ __html: paragraph?.markup }}
            />
        </>
    );
}

export default About;
