import React from 'react';
import Logo from '../../assets/img/clique.png';
import styles from './clique-footer.module.scss';

const CliqueFooter = () => {
    return (
        <footer>
            <div className={styles.footer}>
                <div>
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
        </footer>
    );
};

export default CliqueFooter;
