import React, { useState, useEffect } from 'react';
import PhotoItem from '../photoItem/PhotoItem';
import styles from './photo-grid.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import Loader from '../loader/Loader';

type UmbraccoPhoto = {
    url: string;
};

type UmbraccoPhotoItem = {
    content: {
        properties: {
            photo: UmbraccoPhoto[];
            pageName: string | null;
            orderByNumber: number | null;
        };
    };
};

type PhotoUrl = {
    url: string;
    pageName: string;
    orderByNumber: number | null;
};

function PhotoGrid() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '6595b171-21f8-40ad-9f71-5d8aa9ad0339',
    );

    const [columns, setColumns] = useState(3);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setColumns(2);
            } else {
                setColumns(3);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (error) {
        return <div>Error loading content</div>;
    }

    if (isLoading) {
        return <Loader />;
    }

    const { properties } = data || {};
    const { photos } = properties || {};
    const items: UmbraccoPhotoItem[] = photos?.items || [];

    const photoUrls: PhotoUrl[] = items.map((photo: UmbraccoPhotoItem) => {
        const {
            content: {
                properties: { photo: photoArray, pageName, orderByNumber },
            },
        } = photo;
        const url = photoArray[0].url;
        const formattedPageName = pageName
            ? pageName.toLowerCase().replace(/\s+/g, '-').replace(/-+/g, '-')
            : '';
        return { url, pageName: formattedPageName, orderByNumber };
    });

    const sortedPhotoUrls = photoUrls.sort((a, b) => {
        if (a.orderByNumber === null && b.orderByNumber === null) {
            return 0;
        } else if (a.orderByNumber === null) {
            return 1;
        } else if (b.orderByNumber === null) {
            return -1;
        } else {
            return (a.orderByNumber ?? 0) - (b.orderByNumber ?? 0);
        }
    });

    const rearrangePhotosByRow = (
        photos: PhotoUrl[],
        columns: number,
    ): PhotoUrl[] => {
        const rows = Math.ceil(photos.length / columns);
        const rearrangedPhotos = Array.from({ length: photos.length });

        for (let i = 0; i < photos.length; i++) {
            const rowIndex = Math.floor(i / columns);
            const columnIndex = i % columns;
            const newIndex = columnIndex * rows + rowIndex;
            rearrangedPhotos[newIndex] = photos[i];
        }

        return rearrangedPhotos as PhotoUrl[];
    };

    const rearrangedPhotos = rearrangePhotosByRow(
        sortedPhotoUrls,
        columns,
    ).filter(photo => photo !== undefined);
    return (
        <div className={styles.masonryGrid}>
            {rearrangedPhotos.map(({ url, pageName }, index: number) => (
                <PhotoItem key={index} url={url} pageName={pageName} />
            ))}
        </div>
    );
}

export default PhotoGrid;
