import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './popup.module.scss';

function Popup() {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const hasAcceptedPolicy = document.cookie
            .split('; ')
            .find(row => row.startsWith('acceptedPolicy='));
        if (hasAcceptedPolicy) {
            setIsVisible(false);
        } else {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, []);

    const handlePolicyClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        e.preventDefault();
        document.cookie =
            'acceptedPolicy=true; path=/; max-age=' + 60 * 60 * 24 * 365;
        setIsVisible(false);
        navigate('/privacy-policy');
    };

    if (!isVisible) return null;

    return (
        <div className={styles.popup}>
            <p>
                <span className={styles.welcome}>Welcome to Traffique.</span>{' '}
                This site uses cookies.
                <u>
                    <a
                        className={styles.cookie}
                        href="#"
                        onClick={handlePolicyClick}
                    >
                        {' '}
                        Read our <span className={styles.policy}>policy</span>
                    </a>
                </u>
            </p>
        </div>
    );
}

export default Popup;
