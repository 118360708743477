import React from 'react';
import styles from './home.module.scss';
import Nav from '../../components/nav/Nav';
import PhotoGrid from '../../components/photoGrid/PhotoGrid';
import HeroSection from '../../components/heroSection/HeroSection';
import Popup from '../../components/popup/Popup';

function Home() {
    return (
        <>
            <Nav currentPage="home" />
            <HeroSection />
            <div className={styles.container}>
                <PhotoGrid />
            </div>
            <Popup />
        </>
    );
}

export default Home;
