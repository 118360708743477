import React from 'react';
import styles from './author.module.scss';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';

function AuthorInfo() {
    const { data, error, isLoading } = useGetUmbraccoContentQuery(
        '6595b171-21f8-40ad-9f71-5d8aa9ad0339',
    );

    const author = data?.properties.authorName;
    const dateLocation = data?.properties.dateLocation;
    const imageTitle = data?.properties.imageTitle;
    const imageUrl =
        'https://admintraffique.astroid.com.pl' +
        data?.properties.authorPhoto[0].url;

    return (
        <div className={styles.author}>
            <p>{imageTitle}</p>
            <p className={styles.datePlace}>{dateLocation}</p>
            <a href="/photographers">
                <div className={styles.nameContainer}>
                    <img className={styles.photo} src={imageUrl} />
                    <p className={styles.authorName}>{author}</p>
                </div>
            </a>
        </div>
    );
}

export default AuthorInfo;
