import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './videoJS.css';

interface VideoJSProps {
    url: string;
    playing: boolean;
    controls?: boolean;
    autoPlay?: boolean;
    playsinline?: boolean;
    width?: string;
    height?: string;
    onReady?: (player: any) => void;
    onProgress?: (state: { playedSeconds: number }) => void;
}

const VideoJS = forwardRef((props: VideoJSProps, ref) => {
    const videoRef = React.useRef<HTMLDivElement>(null);
    const playerRef = React.useRef<any>(null);
    const {
        url,
        playing,
        controls = true,
        autoPlay = false,
        playsinline = false,
        width = '100%',
        height = '100%',
        onReady,
        onProgress,
    } = props;

    useImperativeHandle(ref, () => ({
        player: playerRef.current,
    }));

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement('video-js');

            if (videoRef.current) {
                videoRef.current.appendChild(videoElement);
            }

            const player = (playerRef.current = videojs(
                videoElement,
                {
                    controls,
                    autoplay: autoPlay,
                    muted: false,
                    sources: [{ src: url, type: 'video/mp4' }],
                    playsinline,
                    width,
                    height,
                    controlBar: {
                        pictureInPictureToggle: false,
                    },
                },
                () => {
                    console.log('Player is ready');
                    onReady && onReady(player);
                },
            ));

            player.on('timeupdate', () => {
                const playedSeconds = player.currentTime();
                if (typeof playedSeconds === 'number') {
                    onProgress && onProgress({ playedSeconds });
                }
            });
        }

        return () => {
            const player = playerRef.current;
            if (player) {
                player.off('timeupdate');
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [
        url,
        controls,
        autoPlay,
        playsinline,
        width,
        height,
        onReady,
        onProgress,
    ]);

    useEffect(() => {
        if (playerRef.current) {
            const player = playerRef.current;
            if (url !== player.src()[0]?.src) {
                player.src([{ src: url, type: 'video/mp4' }]);
                player.load();
            }

            if (playing) {
                player
                    .play()
                    .catch((error: any) => console.log('Play error:', error));
            } else {
                player.pause();
            }
        }
    }, [url, playing]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} style={{ width, height }} />
        </div>
    );
});

export default VideoJS;
